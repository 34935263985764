/* src/LocationDisplayPage.css */

.location-display-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
  }
  
  .location-display-page h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .location-info {
    font-size: 16px;
    margin-bottom: 15px;
    color: #555;
  }
  
  .map-container {
    width: 100%;
    height: 400px;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
/* Login.css */

/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
  } */
  
  .card {
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .lg-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #4285f4;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .lg-button:hover {
    background-color: #357ae8;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    max-width: 20px;  /* Ensures the image can't exceed 20px */
    max-height: 20px;
    margin-right: 10px;
  }
  
/* Main content wrapper with top padding for navbar */
.home-container, .page-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
}

/* Section styling */
.section {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
}


/* Responsive styles for medium screens (tablets) */
@media (max-width: 768px) {
    .home-container, .page-wrapper {
        padding-left: 10px;
        padding-right: 10px;
        max-width: 100%; /* Use the full width of the screen */
        font-size: 13px; /* Further reduce font size */
    }

    .section {
        margin-bottom: 8px; /* Less spacing between sections */
        padding: 10px; /* Compact padding */
        border-radius: 4px; /* Smaller corners */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); /* Subtle shadow */
        font-size: 0.85rem; /* Smaller font size for sections */
    }
}

/* Responsive styles for smaller screens (phones) */
@media (max-width: 480px) {
    .home-container, .page-wrapper {
        padding-left: 5px;
        padding-right: 5px;
        max-width: 100%;
        font-size: 11px; /* Minimized font size for very small screens */
    }

    .section {
        margin-bottom: 5px; /* Minimal spacing between sections */
        padding: 8px; /* Compact padding */
        border-radius: 3px; /* Rounded but very small corners */
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Lighter shadow for subtlety */
        font-size: 0.75rem; /* Further reduced font size for sections */
    }
}

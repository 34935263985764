.map-container {
  display: flex;
  flex-direction: column; /* Maintain vertical stacking */
  align-items: center; /* Center align horizontally */
  width: 100%; /* Full width */
  padding: 0; /* Remove any extra padding */
  height: 100%; /* Full height */
  position: relative;
  box-sizing: border-box; /* Ensure padding/border don't affect width */
  overflow: hidden; /* Prevent overflow issues */
}

.search-container {
  width: calc(100% - 20px); /* Adjust width to respect margins */
  max-width: 600px; /* Constrain width to a maximum */
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px; /* Add vertical spacing */
  margin-left: auto; /* Automatically calculate left margin */
  margin-right: auto; /* Automatically calculate right margin */
  box-sizing: border-box; /* Include padding in width calculations */
}

.input-container {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure consistent width */
}

.map-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.map-button:hover {
  background-color: #0056b3;
}

.navigation-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
}

.navigation-button:hover {
  background-color: #0056b3;
}

.marker-label {
  font-weight: bold;
  font-size: 16px;
  color: black;
  background-color: white;
  padding: 2px 4px;
  border-radius: 4px;
  display: inline-block;
}

.map-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #0669d3;
  margin-bottom: 20px;
  margin-top: 10px;
}


/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .search-container {
    max-width: 90%; /* Allow more flexibility on smaller screens */
    padding: 12px; /* Reduced padding */
    margin-top: 10px; /* Smaller top margin */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  }

  .input-container {
    margin-bottom: 6px; /* Less spacing between inputs */
  }

  .input {
    padding: 6px; /* Smaller padding for inputs */
    font-size: 12px; /* Smaller font for inputs */
  }

  .map-button, .navigation-button {
    padding: 6px; /* Compact button padding */
    font-size: 12px; /* Smaller font for buttons */
    margin-top: 6px; /* Reduced spacing above buttons */
  }

  .marker-label {
    font-size: 12px; /* Reduced font size for labels */
    padding: 2px 3px; /* Tighter padding for labels */
  }

  .map-title {
    font-size: 18px; /* Smaller font size for map title */
    margin-bottom: 10px; /* Less spacing below the title */
    margin-top: 5px; /* Less spacing above the title */
  }
}
/* src/App.css */

.logout-confirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f9f9f9; /* Light background for better readability */
  }
  
  .logout-confirmation h1 {
    font-size: 24px;
    color: #333; /* Dark gray text color */
    margin-bottom: 20px;
    text-align: center;
  }
  
  .logout-buttons {
    display: flex;
    gap: 20px; /* Add spacing between buttons */
  }
  
  .logout-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .logout-buttons button:first-child {
    background-color: #db302a; /* Red for logout */
    color: #fff; /* White text */
  }
  
  .logout-buttons button:first-child:hover {
    background-color: #6d1512; /* Darker red on hover */
  }
  
  .logout-buttons button:last-child {
    background-color: #007bff; /* Blue for cancel */
    color: #fff; /* White text */
  }
  
  .logout-buttons button:last-child:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
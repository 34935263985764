/* General body styling */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  position: relative;
}

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f4f6f8;
  box-sizing: border-box;
}

/* src/ErrorPage.css */
.error-page {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
}

.error-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 500px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .error-content h1 {
    font-size: 2.5rem;
    color: #159b0e;
    margin-bottom: 1rem;
  }
  
  .error-content p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .error-content button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 1.5rem;
  }
  
  .error-content button:hover {
    background-color: #0056b3;
  }
  
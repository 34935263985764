/* Apply global styles to prevent horizontal scrolling */

  /* Style for the main container */
  .lp-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
  }
  
  /* Style for the heading */
  .lp-h2 {
    color: #333333;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  /* Style for location text */
  .location-text {
    font-size: 1rem;
    color: #555555;
    margin: 1rem 0;
  }
  
  /* Style for the shareable link */
  .shareable-url {
    font-size: 0.9rem;
    color: #007bff;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .shareable-url a {
    color: #007bff;
    text-decoration: none;
  }
  
  .shareable-url a:hover {
    text-decoration: underline;
  }
  
  /* Style for the button */
  .lp-button {
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 1rem;
  }
  
  .lp-button:hover {
    background-color: #0056b3;
  }
  
  /* Style for error messages */
  .error-message {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: 1rem;
    text-align: center;
  }
  
  /* Prevent interactions with the map */
  /* .leaflet-container {
    user-select: none;
  }
   */
  
  